var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"recommond"},[_c('SlListView',{ref:"listView",staticClass:"recommonPar",attrs:{"total":_vm.page.total,"pageIndex":_vm.page.pageIndex},on:{"gotoPage":_vm.gotoPage}},[_c('div',{attrs:{"slot":"search"},slot:"search"},[(_vm.filterIsLoad)?_c('SlSearchForm',{ref:"searchForm",attrs:{"items":_vm.searchItems,"labelWidth":20,"loading":_vm.tableLoading},on:{"reset":function($event){return _vm.gotoPage(_vm.page.pageSize)},"search":function($event){return _vm.gotoPage(_vm.page.pageSize)}},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}):_vm._e()],1),_c('div',[_c('el-link',{staticClass:"mb-8px",attrs:{"type":"primary"},on:{"click":_vm.downloadFileHandle}},[_vm._v("服装尺寸度量标准.pdf")])],1),_c('SlTableToolbar',[_c('SlButton',{attrs:{"type":"primary","boxShadow":"primary","disabled":_vm.selections.length <= 0},on:{"click":_vm.commit}},[_vm._v("批量提交")]),_c('SlButton',{staticClass:"ml-8px",attrs:{"type":"primary","boxShadow":"primary"},on:{"click":function($event){return _vm.productDetail('create','')}}},[_vm._v("创建产品")])],1),_c('SlTableInfo',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"table",attrs:{"tableData":_vm.tableData,"columns":_vm.columns,"multiple":true,"disabledKeys":_vm.disabledKeys},scopedSlots:_vm._u([{key:"prodctInfo",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"prodctInfo"},[_c('SlImage',{attrs:{"src":row.productImageUrlList[0],"size":'10rem'}}),_c('div',{staticClass:"prodctInfo-supplier"},[(row.title)?_c('p',[_vm._v("商品名称: "+_vm._s(row.title))]):_vm._e(),(row.supplierItemNo)?_c('p',[_vm._v("供方货号: "+_vm._s(row.supplierItemNo))]):_vm._e(),(row.erpSpuCode)?_c('p',[_vm._v("SPU:"+_vm._s(row.erpSpuCode))]):_vm._e()])],1)]}},{key:"statusName",fn:function(ref){
var row = ref.row;
return [(row.status.value===6 && row.auditRejectReason)?_c('div',[_c('el-popover',{attrs:{"placement":"top","width":"200","trigger":"hover","content":("原因：" + (row.auditRejectReason))}},[_c('span',{attrs:{"slot":"reference"},slot:"reference"},[_vm._v(_vm._s(row.status.name))])])],1):_c('div',[_vm._v(_vm._s(row.status.name))])]}},{key:"reason",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.status.value === 6 || row.status.value === 9 ? row.auditRejectReason : ''))]}},{key:"times",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v("创建:"+_vm._s(row.createTime))]),_c('p',[_vm._v("更新:"+_vm._s(row.updateTime))])]}},{key:"operation",fn:function(ref){
var row = ref.row;
return [(row.status.value===0 || row.status.value === 6)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.productDetail('modify',row)}}},[_vm._v("编辑")]):_vm._e(),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.productDetail('view',row)}}},[_vm._v("查看")]),(row.status.value===3)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.productDetail('modify',row)}}},[_vm._v("修改")]):_vm._e(),(row.status.value===0)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.commit(row)}}},[_vm._v("提交")]):_vm._e(),(row.status.value===1)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.cancel(row)}}},[_vm._v("撤回")]):_vm._e(),(row.status.value===0)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.deleteProduct(row)}}},[_vm._v("删除")]):_vm._e()]}}]),model:{value:(_vm.selections),callback:function ($$v) {_vm.selections=$$v},expression:"selections"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }